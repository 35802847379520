import React, { useEffect, useState, useContext } from "react"
import { Row, Col, Spinner, CardBody } from "reactstrap"
import {Helmet} from "react-helmet";
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import "../Myclients/datatables.scss"
import "../../assets/scss/manual/manual.scss"
import QuickAccessGridView from "./grid";
import {newPost, get} from 'helpers/axios_helper';
import AppConfig from 'constants/config';
import axios from "axios";
import { AuthContext } from "context/authContext";
import toast from 'react-hot-toast';
import Layout from "components/VerticalLayout";

const QuickAccess = () => {
  const [leaseData, setLeaseData] = useState('');
  const [flaggedLeaseData, setFlaggedLeaseData] = useState('');
  const [clientData, setClientsData] = useState([]);
  const { userData, mode } = useContext(AuthContext)
  const [loading, setLoading] = useState(false);
  const [loadingLease, setLoadingLease] = useState(false);
  const [clientAccess, setClientAccess] = useState({});
  // Table Data
  useEffect(() => {
    if (userData && userData.id) { 
      getClientsData();
      getLeases();
      getFlagged();
    }
  },[userData,mode])

  const getClientsData = async() =>{
    setClientsData([]);
    setLoading(true);
    try {
      const {data} = await axios.get(`${AppConfig.baseUrl}/user_client/get_users_client?user_id=${userData.id}&type=recent&client_type=${mode || 'lessee'}`,{
        headers: { token: localStorage.getItem("token") }
      });
      if(!data.error){
        setClientsData(data.clientData || []);
      }
      else{
          throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
    setLoading(false)
  }

  const getLeases = async() => {
    setLoadingLease(true);
    let data = {from: 'quickaccess',client_type: mode || 'lessee',mode : mode ? mode : 'lessee'}
    try {
      let value = await newPost(`${AppConfig.baseUrl}/lease/listing`, data, {
        headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
        }
      })
      setLeaseData((value.data).slice(0, 4))
    } catch(err){
      console.log('error in listing lease ->',err)
    }  
    setLoadingLease(false);  
  };

  const getFlagged = async() => {
    setLoadingLease(true);
    try {
      let {data} = await get(`${AppConfig.baseUrl}/lease/get_flagged_lease?limit=4&mode=${mode ? mode : 'lessee'}`, {
        headers: {
            'Content-Type': 'application/json',
            'token': localStorage.getItem('token')
        }
      })
      if(data){
        setFlaggedLeaseData(data)
      }
    } catch(err){
      console.log('error in listing get_flagged_lease ->',err)
    }  
    setLoadingLease(false);  
  };

  const getUserClientAccess = async() => {
      try {
          let value = await get(`${AppConfig.baseUrl}/user_client/get_users_client?user_id=${userData.id}`, {
              headers: {
                  'Content-Type': 'application/json',
                  'token': localStorage.getItem('token')
              }
          })
          if(value.error){
              toast.error(value.error.message || 'Something went wrong');
          }
          else{
              let access = {}
              for(const d of value.clientData){
                  if(d && d.client_id){
                      access = {...access, [d.client_id.client]: d.access_type}
                  }
              }
              setClientAccess(access)
          }
      } catch (error) {
          toast.error(error.message || 'Something went wrong');
      }
  }

  useEffect(() => {
      if(userData && userData.id){
          getUserClientAccess();
      }
      else{
          setClientAccess({})
      }
  },[userData])

  const getQuickAccessData = () => {
    if (userData && userData.id) { 
      getClientsData();
      getLeases();
      getFlagged();
    }
  }
  
  return (
    <Layout>
      <div className="page-content">
        <Helmet>
          <title>LeaseVista | Quick Access</title>
        </Helmet>
        {
          loading || loadingLease ? 
          <div style={{textAlign: 'center', width:'100%'}}><Spinner className="text-lbusers" /></div>
          :
          <div className="container-fluid">
              {
                flaggedLeaseData && flaggedLeaseData.length > 0 &&
                <>
                  <Breadcrumb title="Flagged Leases" breadcrumbItem="Flagged Leases" type="flaggedlease"/>
                  <Row>
                      <Col className="col-12">
                          <CardBody className="p-0">
                                <QuickAccessGridView FlaggedLeaseData={flaggedLeaseData} type="flaggedleaseGridView" clientAccess={clientAccess} getQuickAccessData={getQuickAccessData}/> 
                          </CardBody>
                      </Col>
                  </Row>
                </>
              }
              {
                leaseData && leaseData.length > 0 &&
                <>
                  <Breadcrumb title="Recent Leases" breadcrumbItem="Recent Leases" type ="quickrecentlease"/>
                  <Row>
                      <Col className="col-12">
                          <CardBody className="p-0">
                              <QuickAccessGridView RecentLeaseData={leaseData} type = "recentleaseGridView" clientAccess={clientAccess} getQuickAccessData={getQuickAccessData}/> 
                          </CardBody>
                      </Col>
                  </Row>
                </>
              }
              {
                clientData && clientData.length > 0 &&
                <>
                  <Breadcrumb title="Recent Clients" breadcrumbItem="Recent clients" type ="quickrecentclients"/>
                  <Row>
                      <Col className="col-12">
                          <CardBody className="p-0">
                              <QuickAccessGridView RecentClientData={clientData} type="recentClientGridView" getClientsData={getClientsData}/> 
                          </CardBody>
                      </Col>
                  </Row>
                </>
              }
          </div>
        }
      </div>
    </Layout>
  )
}

export default QuickAccess
