import React, {useEffect, useState, useContext} from "react";
import { useNavigate } from "react-router-dom"
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "constants/config";
import { Row, Col, CardBody, Card, Container, Spinner, Button, TabPane, NavLink, NavItem, TabContent, Nav, } from "reactstrap";
import {Helmet} from "react-helmet";
import { AuthContext } from "context/authContext"
import classnames from "classnames";

const ClientSelection = () => {
    const [lesseeClients, setLesseeClients] = useState([]);
    const [lessorClients, setLessorClients] = useState([]);
    const [originalClients, setOriginalClients] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);
    const [userData, setUserData] = useState({});
    const [activeTab, setActiveTab] = useState("1");
    const [loading, setLoading] = useState(false)
    const { getProcessingStatus, getProcessingSearch, reloadUserData } = useContext(AuthContext)
    const navigate = useNavigate();
    const [selectedAllLessee, setSelectedAllLessee] = useState(false);
    const [selectedAllLessor, setSelectedAllLessor] = useState(false);
    const [custData, setCustData] = useState({})

    useEffect(() => {
        getTokenData()
    },[])

    const getCustomerData = async() => {
        setLoading(true)
        try {
            const id = userData.cust_info._id;
            const {data} = await axios.get(`${AppConfig.baseUrl}/customer/getCustomerData?id=${id}`,{
                headers: { token: localStorage.getItem("token") }
            });
            if(!data.error){
                setCustData(data.custData)
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong')
        }
        setLoading(false)
    }
    useEffect(() => {
    if(userData && userData.cust_info && userData.cust_info._id && userData.user_id){
        getCustomerData()
        getUserClients()
    }
    },[userData])

    const getTokenData = async() => {
        setLoading(true)
        try {
            const {data} = await axios.post(`${AppConfig.baseUrl}/users/token`,null,{
                headers: { token: localStorage.getItem("temp_token") }
            });
            if(!data.error){
                setUserData(data.tokenData || {});
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong')
        }
        setLoading(false)
    }

    const getUserClients = async() => {
        setLoading(true)
        try {
            const {data} = await axios.get(`${AppConfig.baseUrl}/user_client/get_users_client?user_id=${userData.user_id}`,{
                headers: { token: localStorage.getItem("temp_token") }
            });
            if(!data.error){
                setOriginalClients(data.clientData || []);
                const lessee = data.clientData ? data.clientData.filter(l => l.client_id.client_type == 'lessee') : []
                setLesseeClients(lessee);
                const lessor = data.clientData ? data.clientData.filter(l => l.client_id.client_type == 'lessor') : []
                setLessorClients(lessor);
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong')
        }
        setLoading(false)
    }

    const CheckAll = (e,type) =>{
        if(e.target.checked === true && type == 'lessee'){
            const lessor = selectedClients.filter(v => v.client_id.client_type == 'lessor')
            setSelectedClients([...lessor,...lesseeClients])
            setSelectedAllLessee(true)
        }else if(e.target.checked === true && type == 'lessor'){
            const lessee = selectedClients.filter(v => v.client_id.client_type == 'lessee')
            setSelectedClients([...lessee,...lessorClients])
            setSelectedAllLessor(true)
        }else if(type == 'lessor'){
            const lessee = selectedClients.filter(v => v.client_id.client_type == 'lessee')
            setSelectedClients(lessee)
            setSelectedAllLessor(false)
        }else{
            const lessor = selectedClients.filter(v => v.client_id.client_type == 'lessor')
            setSelectedClients(lessor)
            setSelectedAllLessee(false)
        }
    }

    const checkArr = (data,type) => {
        let newArr = [...selectedClients];
        let index = newArr.findIndex((e) => e == data);
        if(index > -1){
            newArr.splice(index, 1);
            setSelectedClients(newArr);
        } else {
            newArr.push(data);
            setSelectedClients(newArr);
        }
        if(type == 'lessor'){
            const lessor = newArr.filter(v => v.client_id.client_type == 'lessor')
            setSelectedAllLessor(lessorClients.length == lessor.length)
        }else{
            const lessee = newArr.filter(v => v.client_id.client_type == 'lessee')
            setSelectedAllLessee(lesseeClients.length == lessee.length)
        }
    }

    const showCheck = (data) => {
        let a = selectedClients.filter((e) => e == data);
        return a.length > 0 ? true : false
    }

    const handleSubmit = async() => {
        setLoading(true);
        try {
            const response = await axios.get('https://api.ipify.org?format=json')
            const ip = response && response.data ? response.data.ip : ''
            const clientIdArr = selectedClients.map(v => v.client_id._id)
            const {data} = await axios.post(`${AppConfig.baseUrl}/users/setSelectedClients`, {clients: clientIdArr,ip_address: ip}, {
                headers: { token: localStorage.getItem("temp_token") }
            });
            if(!data.error){
                if(data.is_2fa_active){
                    navigate({pathname: "/factorauth"});
                }
                else if(!data.terms_checked){
                    navigate(`/terms_and_conditions/accept`);
                }
                else{
                    await localStorage.setItem('token', data.token);
                    await localStorage.setItem('company', data.company.toLowerCase());
                    reloadUserData()
                    getProcessingStatus()
                    getProcessingSearch()
                    if(data.is_1st_login){
                        navigate("/set_password");
                    }
                    else{
                        toast.success('Logged in Successfully')
                        window.location.href = `/${data.company.toLowerCase()}/quickaccess`
                    }
                }
            }
            else{
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong')
        }
        setLoading(false);
    }

    const handleChange = (e,type) => {
        if(e.target.value && type == 'lessee'){
            const newClients = originalClients.filter(v => v.client_id.client.toLowerCase().includes(e.target.value) && v.client_id.client_type == 'lessee')
            setLesseeClients(newClients)
        }else if(e.target.value && type == 'lessor'){
            const newClients = originalClients.filter(v => v.client_id.client.toLowerCase().includes(e.target.value) && v.client_id.client_type == 'lessor')
            setLessorClients(newClients)
        }else if(type == 'lessor'){
            const newClients = originalClients.filter(v => v.client_id.client_type == 'lessor')
            setLessorClients(newClients)
        }else{
            const newClients = originalClients.filter(v => v.client_id.client_type == 'lessee')
            setLesseeClients(newClients)
        }
    }

    return(
        <React.Fragment>
        <Helmet>
          <title>LeaseVista | Client Selection </title>
        </Helmet>
        <div className="vertical-center" style={{width: '100%'}}>
          <Container>
            <Row className="justify-content-center">
                <Col xs={10}>
                    <Card>
                        <div className="bg-lbusers bg-softbg-soft-lbusers login-color">
                            <Row>
                                <Col xs={12}>
                                    <h3 className="text-white" style={{margin: '2%', textAlign: 'center'}}>Client Selection</h3>
                                </Col>
                            </Row>
                        </div>
                        <CardBody className="pt-0">
                            <Row className="mt-4 mb-4">
                                <Col className="font-size-16">
                                    Please select your clients below. In order to make changes to your selection, please log out and log back in.
                                </Col>
                            </Row>
                            <Row>
                                <Nav tabs className="nav-tabs-custom nav-justified">
                                    <NavItem style={{textAlign: 'left'}}>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: activeTab === "1",
                                            })}
                                            onClick={() => {
                                                setActiveTab("1");
                                            }}
                                        >
                                            <span className="d-block d-sm-none">
                                                <i className="fas fa-home"></i>
                                            </span>
                                            <span className="d-none d-sm-block font-size-16">LESSEE</span>
                                        </NavLink>
                                    </NavItem>
                                    { custData && custData.modules && custData.modules.includes('lessor_gasb') &&
                                        <NavItem style={{textAlign: 'left'}}>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: activeTab === "2",
                                                })}
                                                onClick={() => {
                                                setActiveTab("2");
                                                }}
                                            >
                                                <span className="d-block d-sm-none">
                                                    <i className="far fa-envelope"></i>
                                                </span>
                                                <span className="d-none d-sm-block font-size-16">LESSOR</span>
                                            </NavLink>
                                        </NavItem>
                                    }
                                </Nav>
                                <TabContent
                                    activeTab={activeTab}
                                    className="p-3 text-muted"
                                >
                                    <TabPane tabId="1">
                                        <Row className="mt-4">
                                            <Col md={4}>
                                                <input id="search_client" placeholder="Search Client" type="text" className="form-control" onChange={(e)=>{handleChange(e,'lessee')}} />
                                            </Col>
                                        </Row>

                                        {
                                            loading ?
                                                <div className="text-center mt-4"><Spinner color="primary" className="text-lbusers" /></div>
                                            : lesseeClients.length < 1 ? 
                                                <>
                                                    <div className="text-center mt-4">No data found</div>                                                                
                                                </> 
                                            :
                                            <>
                                                <Row className="mt-4">
                                                    <Col>
                                                        <input id="all_leases" type="checkbox" className="form-check-input" checked={selectedAllLessee} onClick={((e)=>CheckAll(e,'lessee'))} />
                                                        <label
                                                            className="form-check-label ms-2"
                                                            htmlFor="all_leases"
                                                        >
                                                            Select All
                                                        </label>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2" style={{overflowY: 'scroll', overflowX: 'hidden', maxHeight: '40vh'}}>
                                                {lesseeClients.map((eachClient) =>
                                                    <Col md="4" key={eachClient._id}>
                                                        <div className="d-flex mt-4">
                                                            <div className="form-check">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id={eachClient._id}
                                                                    checked={showCheck(eachClient)}
                                                                    onClick={e => {checkArr(eachClient,'lessee')}}
                                                                />
                                                            </div>
                                                            <label
                                                                className="form-check-label ms-2"
                                                                htmlFor={eachClient._id}
                                                            >
                                                                {eachClient.client_id.client}
                                                            </label>
                                                        </div>
                                                    </Col>
                                                )}
                                                </Row>                                  
                                                
                                            </>
                                        }
                                    </TabPane>
                                    <TabPane tabId="2">
                                        <Row className="mt-4">
                                            <Col md={4}>
                                                <input id="search_client" placeholder="Search Client" type="text" className="form-control" onChange={(e)=>{handleChange(e,'lessor')}} />
                                            </Col>
                                        </Row>

                                        {
                                            loading ?
                                                <div className="text-center mt-4"><Spinner color="primary" className="text-lbusers" /></div>
                                            : lessorClients.length < 1 ? 
                                                <>
                                                    <div className="text-center mt-4">No data found</div>                                                                
                                                </> 
                                            :
                                            <>
                                                <Row className="mt-4">
                                                    <Col>
                                                        <input id="all_leases" type="checkbox" className="form-check-input" checked={selectedAllLessor} onClick={((e)=>CheckAll(e,'lessor'))} />
                                                        <label
                                                            className="form-check-label ms-2"
                                                            htmlFor="all_leases"
                                                        >
                                                            Select All
                                                        </label>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-2" style={{overflowY: 'scroll', overflowX: 'hidden', maxHeight: '40vh'}}>
                                                {lessorClients.map((eachClient) =>
                                                    <Col md="4" key={eachClient._id}>
                                                        <div className="d-flex mt-4">
                                                            <div className="form-check">
                                                                <input
                                                                    type="checkbox"
                                                                    className="form-check-input"
                                                                    id={eachClient._id}
                                                                    checked={showCheck(eachClient)}
                                                                    onClick={e => {checkArr(eachClient,'lessor')}}
                                                                />
                                                            </div>
                                                            <label
                                                                className="form-check-label ms-2"
                                                                htmlFor={eachClient._id}
                                                            >
                                                                {eachClient.client_id.client}
                                                            </label>
                                                        </div>
                                                    </Col>
                                                )}
                                                </Row>                                  
                                                
                                            </>
                                        }
                                    </TabPane>
                                </TabContent>
                            </Row>
                            <Row className="mt-4">
                                <Col>
                                    <Button className="btn btn-md btn-lbusers" type="button" onClick={handleSubmit}>SUBMIT</Button>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
          </Container>
        </div>
        </React.Fragment>
    )
}

export default ClientSelection;