/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useContext } from "react"
import {Helmet} from "react-helmet";
import { Card, CardBody, Container,Modal, Row, Col, Spinner } from "reactstrap"
import Breadcrumbs from "components/Common/BreadcrumbBulkUpload";
import toast from "react-hot-toast";
import axios from "axios";
import AppConfig from "constants/config";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContext } from "context/authContext";
import MUIDataTable from "mui-datatables";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { getMuiTheme } from 'helpers/mui_theme'
import Layout from "components/VerticalLayout";

const BulkUploadDetails = () => {
    const { userData, mode } = useContext(AuthContext)
    const params = useParams();
    const navigate = useNavigate()
    const [leaseData, setLeaseData] = useState([{}]);
    const [loadingLeases, setLoadingLeases] = useState(true);
    const [batchData, setBatchData] = useState({});
    const [loading, setLoading] = useState(false)
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [selectedBulkLease, setSelectedBulkLease] = useState({});
    const [leaseCount, setLeaseCount] = useState({});
    let [columns, setColumns] = useState([{}]);
    const [file, setFile] = useState("");
    const [localFile, setLocalFile] = useState("");
    const [leasesWithoutPayment, setLeasesWithoutPayment] = useState(0);

    columns = [
        {
            label: 'Sr. No.',
            name: 'sr_no',
            options: {
                filter: false,
                sort: false,
                setCellProps: () => ({style: {minWidth: '100px',height: '40px'}}),
                setCellHeaderProps: () => ({style: {minWidth: '100px',height: '50px'}}),
                customBodyRender:(value, tableMeta, updateValue)=> (tableMeta && tableMeta.rowIndex) + 1,
            }
        },
        {
            label: 'Lease Name',
            name: 'lease_name',
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite:(dataIndex)=> leaseData && leaseData[dataIndex] && leaseData[dataIndex].lease_name
            }
        },
        {
            label: 'Client Name',
            name: 'client_id.client',
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite:(dataIndex)=> leaseData && leaseData[dataIndex] && leaseData[dataIndex].client_id && leaseData[dataIndex].client_id.client
            }
        },
        {
            label: 'Status',
            name: 'status',
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite:(dataIndex)=>(
                    <div
                        className={`drp table-data-margin text-${leaseData && leaseData[dataIndex] && leaseData[dataIndex].is_reviewed ? 'success' : leaseData && leaseData[dataIndex] && leaseData[dataIndex].is_payment_uploaded ? 'warning' : 'danger'}`}
                        style={{ fontWeight: 'bold' }}
                    >
                        {leaseData && leaseData[dataIndex] && leaseData[dataIndex].is_reviewed ? 'REVIEWED' : leaseData && leaseData[dataIndex] && leaseData[dataIndex].is_payment_uploaded ? 'READY' : 'PENDING PAYMENT'}
                    </div>
                ),
            }
        },
        {
            label: 'Actions',
            name: 'actions',
            options: {
                filter: false,
                sort: true,
                customBodyRenderLite:(dataIndex)=>{
                    return <>
                        {
                            leaseData && leaseData[dataIndex] && leaseData[dataIndex].is_reviewed ?
                                <button
                                    type="button"
                                    className="btn btn-lbusers btn-sm"
                                    onClick={() => {
                                        navigate(`/${localStorage.getItem('company')}/leases/details/${leaseData && leaseData[dataIndex] && leaseData[dataIndex].lease_id}/other`)
                                    }}
                                    disabled={loading}
                                >
                                    {
                                        loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                    }
                                    View
                                </button>
                                :
                                leaseData && leaseData[dataIndex] && leaseData[dataIndex].is_payment_uploaded && leaseCount.current_leases >= leaseCount.max_leases ?
                                    <span className="d-inline-block" dataToggle="tooltip" dataPlacement="top" title="Lease limit reached">
                                        <button
                                            type="button"
                                            className="btn btn-lbusers btn-sm"
                                            onClick={() => {
                                                navigate(`/${localStorage.getItem('company')}/leases/add_bulk_lease?bulk_lease_id=${leaseData && leaseData[dataIndex] && leaseData[dataIndex]._id}`)
                                            }}
                                            disabled={leaseCount.current_leases >= leaseCount.max_leases || loading}
                                        >
                                            {
                                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                            }
                                            Review
                                        </button>
                                    </span>
                                    :
                                    leaseData && leaseData[dataIndex] && leaseData[dataIndex].is_payment_uploaded ?
                                        <button
                                            type="button"
                                            className="btn btn-lbusers btn-sm"
                                            onClick={() => {
                                                navigate(`/${localStorage.getItem('company')}/leases/add_bulk_lease?bulk_lease_id=${leaseData && leaseData[dataIndex] && leaseData[dataIndex]._id}`)
                                            }}
                                            disabled={loading}
                                        >
                                            {
                                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                            }
                                            Review
                                        </button>
                                        : ''
                        }
                        {
                            leaseData && leaseData[dataIndex] && !leaseData[dataIndex].is_reviewed &&
                            <button
                                type="button"
                                className="btn btn-lbusers btn-sm ms-1"
                                onClick={() => {
                                    setDeleteOpen(true);
                                    setSelectedBulkLease(leaseData && leaseData[dataIndex] && leaseData[dataIndex]);
                                }}
                                disabled={loading}
                            >
                                {
                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                }
                                Delete
                            </button>
                        }
                    </>
                },
            }
        },
    ]

    useEffect(() => {
        getLeaseData();
        getBatchData();
    }, [mode])

    useEffect(() => {
        if (userData && userData.cust_info && userData.cust_info._id) {
            getLeaseCount()
        }
    }, [userData])

    useEffect(() => {
        if (leaseCount && leaseCount.current_leases >= 0) {
            setColumns([
                {
                    label: 'Sr. No.',
                    name: 'sr_no',
                    options: {
                        filter: false,
                        sort: false,
                        setCellProps: () => ({style: {minWidth: '100px',height: '40px'}}),
                        setCellHeaderProps: () => ({style: {minWidth: '100px'}}),
                        customBodyRender:(value, tableMeta, updateValue)=> (tableMeta && tableMeta.rowIndex) + 1,
                    }
                },
                {
                    label: 'Lease Name',
                    name: 'lease_name',
                    options: {
                        filter: false,
                        sort: true,
                        customBodyRenderLite:(dataIndex)=> leaseData && leaseData[dataIndex] && leaseData[dataIndex].lease_name
                    }
                },
                {
                    label: 'Client Name',
                    name: 'client_id.client',
                    options: {
                        filter: false,
                        sort: true,
                        customBodyRenderLite:(dataIndex)=> leaseData && leaseData[dataIndex] && leaseData[dataIndex].client_id && leaseData[dataIndex].client_id.client
                    }
                },
                {
                    label: 'Status',
                    name: 'status',
                    options: {
                        filter: false,
                        sort: true,
                        customBodyRenderLite:(dataIndex)=>(
                            <div
                                className={`drp table-data-margin text-${leaseData && leaseData[dataIndex] && leaseData[dataIndex].is_reviewed ? 'success' : leaseData && leaseData[dataIndex] && leaseData[dataIndex].is_payment_uploaded ? 'warning' : 'danger'}`}
                                style={{ fontWeight: 'bold' }}
                            >
                                {leaseData && leaseData[dataIndex] && leaseData[dataIndex].is_reviewed ? 'REVIEWED' : leaseData && leaseData[dataIndex] && leaseData[dataIndex].is_payment_uploaded ? 'READY' : 'PENDING PAYMENT'}
                            </div>
                        ),
                    }
                },
                {
                    label: 'Actions',
                    name: 'actions',
                    options: {
                        filter: false,
                        sort: true,
                        customBodyRenderLite:(dataIndex)=>{
                            return <>
                                {
                                    leaseData && leaseData[dataIndex] && leaseData[dataIndex].is_reviewed ?
                                        <button
                                            type="button"
                                            className="btn btn-lbusers btn-sm"
                                            onClick={() => {
                                                navigate(`/${localStorage.getItem('company')}/leases/details/${leaseData && leaseData[dataIndex] && leaseData[dataIndex].lease_id}/other`)
                                            }}
                                            disabled={loading}
                                        >
                                            {
                                                loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                            }
                                            View
                                        </button>
                                        :
                                        leaseData && leaseData[dataIndex] && leaseData[dataIndex].is_payment_uploaded && leaseCount.current_leases >= leaseCount.max_leases ?
                                            <span className="d-inline-block" dataToggle="tooltip" dataPlacement="top" title="Lease limit reached">
                                                <button
                                                    type="button"
                                                    className="btn btn-lbusers btn-sm"
                                                    onClick={() => {
                                                        navigate(`/${localStorage.getItem('company')}/leases/add_bulk_lease?bulk_lease_id=${leaseData && leaseData[dataIndex] && leaseData[dataIndex]._id}`)
                                                    }}
                                                    disabled={leaseCount.current_leases >= leaseCount.max_leases || loading}
                                                >
                                                    {
                                                        loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                                    }
                                                    Review
                                                </button>
                                            </span>
                                            :
                                            leaseData && leaseData[dataIndex] && leaseData[dataIndex].is_payment_uploaded ?
                                                <button
                                                    type="button"
                                                    className="btn btn-lbusers btn-sm"
                                                    onClick={() => {
                                                        navigate(`/${localStorage.getItem('company')}/leases/add_bulk_lease?bulk_lease_id=${leaseData && leaseData[dataIndex] && leaseData[dataIndex]._id}`)
                                                    }}
                                                    disabled={loading}
                                                >
                                                    {
                                                        loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                                    }
                                                    Review
                                                </button>
                                                : ''
                                }
                                {
                                    !leaseData && leaseData[dataIndex] && leaseData[dataIndex].is_reviewed &&
                                    <button
                                        type="button"
                                        className="btn btn-lbusers btn-sm ms-1"
                                        onClick={() => {
                                            setDeleteOpen(true);
                                            setSelectedBulkLease(leaseData && leaseData[dataIndex] && leaseData[dataIndex]);
                                        }}
                                        disabled={loading}
                                    >
                                        {
                                            loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                        }
                                        Delete
                                    </button>
                                }
                            </>
                        },
                    }
                },
            ]);
        }
    }, [leaseCount, leaseData])

    const getLeasesWithoutPayment = async() =>{
        const leases = await leaseData.filter(v => !v.is_payment_uploaded);
        setLeasesWithoutPayment(leases.length);
    }

    useEffect(() => {
        if (leaseData && leaseData.length > 0) {
            getLeasesWithoutPayment()
        }
        else {
            setLeasesWithoutPayment(0);
        }
    }, [leaseData])

    const getLeaseData = async () => {
        setLoadingLeases(true);
        setLeaseData([]);
        try {
            const batchId = params.id;
            const { data } = await axios.get(`${AppConfig.baseUrl}/bulk_lease/get_bulk_leases?id=${batchId}&mode=${mode ? mode : 'lessee'}`, {
                headers: { token: localStorage.getItem("token") }
            });
            if (!data.error) {
                setLeaseData(data.leaseData);
            }
            else {
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoadingLeases(false)
    }

    const getLeaseCount = async () => {
        setLoadingLeases(true);
        try {
            const { data } = await axios.get(`${AppConfig.baseUrl}/customer/get_lease_count?id=${userData.cust_info._id}`, {
                headers: { token: localStorage.getItem("token") }
            });
            if (!data.error) {
                setLeaseCount(data.leaseCount);
            }
            else {
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoadingLeases(false)
    }

    const getBatchData = async () => {
        setBatchData([]);
        try {
            const batchId = params.id;
            const { data } = await axios.get(`${AppConfig.baseUrl}/bulk_lease/get_batch_data?id=${batchId}&mode=${mode ? mode : 'lessee'}`, {
                headers: { token: localStorage.getItem("token") }
            });
            if (!data.error) {
                setBatchData(data.batchData);
            }
            else {
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
    }

    const handleDeleteLease = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const { data } = await axios.post(`${AppConfig.baseUrl}/bulk_lease/delete_bulk_lease`, { lease_id: selectedBulkLease._id, mode: mode ? mode : 'lessee' }, {
                headers: { token: localStorage.getItem("token") }
            });
            if (!data.error) {
                toast.success('Lease deleted successfully');
            }
            else {
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
        getLeaseData()
        setSelectedBulkLease({})
        setDeleteOpen(false)
    }

    const handleChangeFile = (e) => {
        e.preventDefault();
        setLocalFile(e.target.value)
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        setFile(files[0])
    };

    const uploadBulkData = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const dataArray = new FormData();
            const batchId = params.id;
            dataArray.append('type', 'payment')
            dataArray.append('batch_id', batchId)
            dataArray.append('file', file)
            dataArray.append('mode', mode ? mode : 'lessee')
            const { data } = await axios.post(`${AppConfig.baseUrl}/bulk_lease/bulk_add`, dataArray, {
                headers: { token: localStorage.getItem("token") }
            });
            if (!data.error) {
                toast.success(data.title);
            }
            else {
                throw new Error(data.title);
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        }
        setLoading(false)
        setFile("");
        setLocalFile("");
        getBatchData();
        getLeaseData();
    }

    return (
        <Layout>
            <div className="page-content">
                <Helmet>
                    <title>LeaseVista | Batches</title>
                </Helmet>
                <Container fluid>
                    <Breadcrumbs title="batch_details"
                        breadcrumbItem={`Batch ID - ${batchData && batchData.batch_no || ''}`}
                        type="batch_details"
                        batch_id={batchData && batchData._id}
                        mode={mode ? mode : 'lessee'}
                    />
                    {
                        leasesWithoutPayment && leasesWithoutPayment > 0 ?
                            <Card>
                                <CardBody>
                                    <h5 className="card-title mb-4">Upload Payments</h5>
                                    <form onSubmit={uploadBulkData}>
                                        <Row className="mt-4">
                                            <Col sm="4">
                                                <div>
                                                    <input
                                                        className="form-control"
                                                        id="formFileLg"
                                                        type="file"
                                                        value={localFile || ""}
                                                        accept=".xlsx"
                                                        onChange={handleChangeFile}
                                                    />
                                                </div>
                                            </Col>
                                            <Col sm="8" className="d-flex justify-content-between">
                                                <button type="submit" className="btn btn-lbusers" disabled={loading || !file} >
                                                    {
                                                        loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                                    }
                                                    Submit
                                                </button>
                                            </Col>
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                            : ''
                    }
                    <Card>
                        <CardBody>
                            <ThemeProvider theme={createTheme({components: {...getMuiTheme(),
                                MUIDataTableToolbar:{
                                    styleOverrides:{
                                    actions: {
                                        display: 'contents'
                                    }
                                    }
                                }
                            }})}>
                            <MUIDataTable
                                title=""
                                data={leaseData}
                                columns={columns}
                                options={{ 
                                rowsPerPage:10, 
                                print:false, 
                                download:false, 
                                filter:false, 
                                viewColumns:false,
                                selectableRows:"none", 
                                search:false, 
                                pagination:false,
                                rowsPerPageOptions:[], 
                                responsive:'scroll',
                                textLabels: {
                                    body: {
                                      noMatch: loadingLeases ? <div><Spinner color="primary" className="text-lbusers" /></div> : "Sorry, no matching records found"
                                    },
                                    viewColumns: {
                                    title: "",
                                    },
                                } 
                                }}
                            />
                            </ThemeProvider>
                        </CardBody>
                    </Card>
                </Container>
            </div>
            <Modal
                isOpen={deleteOpen}
                toggle={() => {
                    setDeleteOpen(!deleteOpen);
                    setSelectedBulkLease({});
                }}
                centered
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Delete Bulk Lease</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setDeleteOpen(false);
                            setSelectedBulkLease({});
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body">
                    <form onSubmit={handleDeleteLease}>
                        <div className="mb-3">
                            Are you sure you want to delete this bulk lease ?
                        </div>
                        <div className="modal-footer mt-3">
                            <button
                                type="button"
                                className="btn btn-lbusers"
                                onClick={() => {
                                    setDeleteOpen(false);
                                    setSelectedBulkLease({});
                                }}
                                disabled={loading}
                            >
                                {
                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                }
                                No
                            </button>
                            <button type="submit" className="btn btn-lbusers" disabled={loading} >
                                {
                                    loading && <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                                }
                                Yes
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </Layout>
    )
}

export default BulkUploadDetails