import React, { useEffect, useState, useContext } from "react"
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { NavLink } from "react-router-dom";
import AddClientToLease from "pages/Leases/addClientToLease";
import toast from 'react-hot-toast';
import { get } from 'helpers/axios_helper';
import AppConfig from 'constants/config'
import { AuthContext } from "context/authContext"
import EditNonAccounting from "pages/Leases/editNonAccounting"
import AddVariablePayment from '../../pages/Leases/addVariablePayment'
import moment from 'moment';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            fontWeight: 400,
            fontSize: "0.8125rem",
            color: "black",
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function CustomizedMenus(prop) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { userData, mode } = useContext(AuthContext)
    const [clientAccess, setClientAccess] = useState({});
    const [editNonAccounting, setEditNonAccounting] = useState(false)
    const [addClientLeasePopup, setAddClientLeasePopup] = useState(false)
    const [selectedlease, setSelectedLease] = useState('')
    const [variablePaymentOpen, setVariablePaymentOpen] = useState(false)
    const [variablePaymentArr, setVariablePaymentArr] = useState([])
    const [shortTerm, setShortTerm] = useState(false)

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleVariablePayment = () => {
        setVariablePaymentOpen(!variablePaymentOpen)
        setSelectedLease('')
    }

    const AddClientLeaseToggle = () => {
        setAddClientLeasePopup(!addClientLeasePopup)
    }

    const EditNonAccountingToggle = () => {
        setEditNonAccounting(!editNonAccounting)
        setSelectedLease('')
    }

    const getUserClientAccess = async () => {
        try {
            let value = await get(`${AppConfig.baseUrl}/user_client/get_users_client?user_id=${userData.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            if (value.error) {
                toast.error(value.error.message || 'Something went wrong')
            }
            else {
                let access = {}
                for (const d of value.clientData) {
                    if (d && d.client_id) {
                        access = { ...access, [d.client_id.client]: d.access_type }
                    }
                }
                setClientAccess(access)
            }
        } catch (error) {
            toast.error(error.message || 'Something went wrong')
        }
    }

    useEffect(() => {
        if (userData && userData.id) {
            getUserClientAccess();
        }
        else {
            setClientAccess({})
        }
    }, [userData])

    useEffect(() => {
        if (prop.user) {
            getModifyLease()
        }
    }, [prop.user])

    const getModifyLease = async () => {
        try {
            let value = await get(`${AppConfig.baseUrl}/lease/get_modify_lease?lease_id=${prop.user._id}&mode=${mode}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': localStorage.getItem('token')
                }
            })
            if (value) {
                const leaseData = value.modLeaseData
                if (leaseData && leaseData.length > 0) {
                    if ((moment(prop.user.terminal_end_date).diff(moment(leaseData[0].terminal_end_date), 'months') < 12) && leaseData[0].is_short_term) {
                        setShortTerm(true)
                    } else {
                        setShortTerm(false)
                    }
                } else {
                    if (moment(prop.user.terminal_end_date).diff(moment(prop.user.commencement), 'months') < 12) {
                        setShortTerm(true)
                    } else {
                        setShortTerm(false)
                    }
                }
            }
        } catch (err) {
            console.log('error in listing lease ->', err)
        }
    }

    return (
        <div>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                sx={{backgroundColor: "#005691", padding: "5px", fontSize: "0.71094rem !important", fontWeight: "400 !important", padding:"0.25rem", ":hover": {backgroundColor: "#005691"}}}
            >
                Actions
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
            >
                {prop.user.audit_category !== 'drafted' && <NavLink to={`/${localStorage.getItem('company')}/leases/details/${prop.user._id}/other`} className='text-dark'><MenuItem > View</MenuItem></NavLink>}
                {
                    prop.user && prop.user.client_id && clientAccess[prop.user.client_id.client] === 'full_access' &&
                    <>
                        {!prop.user.is_terminated && prop.user.audit_category !== 'drafted' && <MenuItem onClick={() => { setAnchorEl(null); toggleVariablePayment(); setSelectedLease(prop.user._id) }}> Variable Payments</MenuItem>}
                        {(!prop.user.is_terminated && prop.user.audit_category !== 'drafted') && <MenuItem onClick={() => { setAnchorEl(null); setEditNonAccounting(true); setSelectedLease(prop.user._id) }}> Edit Non-Accounting </MenuItem>}
                        {(!prop.user.is_terminated) && <a className="w-100" href={prop.user.is_modified ? `/${localStorage.getItem('company')}/leases/edit_modify_lease?lease_id=${prop.user._id}&client_id=${prop.user.client_id._id}` : `/${localStorage.getItem('company')}/leases/edit?lease_id=${prop.user._id}&client_id=${prop.user.client_id._id}`}> <MenuItem>Edit</MenuItem></a>}
                        {(!prop.user.is_terminated && prop.user.audit_category !== 'drafted' && mode != 'lessor') && <MenuItem onClick={() => { setAnchorEl(null); prop.setImpairment(true); prop.setSelectedLease(prop.user) }}> Impairment</MenuItem>}
                        {(prop.user.is_modified || prop.user.is_terminated) && prop.user.audit_category !== 'drafted' && <MenuItem onClick={() => { setAnchorEl(null); prop.setRevertOpen(true); prop.setSelectedLease(prop.user); setSelectedLease(prop.user._id) }}> Revert</MenuItem>}
                        {!prop.user.is_terminated && prop.user.audit_category !== 'drafted' && <MenuItem onClick={() => { setAnchorEl(null); prop.setModifyOpen(true); prop.setSelectedLease(prop.user) }}> Modify (GAAP)</MenuItem>}
                        {(!prop.user.is_modified && !prop.user.is_terminated && prop.user.audit_category !== 'drafted') && <MenuItem onClick={() => { setAnchorEl(null); setAddClientLeasePopup(true)}}> Clone</MenuItem>}
                        {!prop.user.is_terminated && prop.user.audit_category !== 'drafted' && <MenuItem onClick={() => { setAnchorEl(null); prop.setTerminateOpen(true); prop.setSelectedLease(prop.user) }}> Terminate</MenuItem>}
                        <MenuItem onClick={() => { setAnchorEl(null); prop.setDeleteOpen(true); prop.setSelectedLease(prop.user) }}> Delete</MenuItem>
                        {mode != 'lessor' && !prop.user.is_terminated && prop.user.audit_category !== 'drafted' && !prop.user.is_short_term && shortTerm && prop.user.classification == 'operating' && <MenuItem onClick={() => { setAnchorEl(null); prop.setShortTermOpen(true); prop.setSelectedLease(prop.user) }}>Apply ST Exemption</MenuItem>}
                        {mode != 'lessor' && !prop.user.is_terminated && prop.user.audit_category !== 'drafted' && prop.user.is_short_term && <MenuItem onClick={() => { setAnchorEl(null); prop.setShortTermOpen(true); prop.setSelectedLease(prop.user) }}>Un-apply ST Exemption</MenuItem>}
                        {!prop.user.is_terminated && prop.user.audit_category !== 'drafted' && mode != 'lessor' && <MenuItem className="text-success text-black" onClick={() => { setAnchorEl(null); prop.setUsefulLife(true); prop.setSelectedLease(prop.user) }}> Change Useful Life</MenuItem>}
                    </>
                }
            </StyledMenu>
            <AddClientToLease addOpen={addClientLeasePopup} toggleAdd={AddClientLeaseToggle} setOpenPopup={setAddClientLeasePopup} type='clone' lease_id={prop.user._id} />
            <EditNonAccounting editOpen={editNonAccounting} toggleEdit={EditNonAccountingToggle} setOpenPopup={setEditNonAccounting} lease_id={selectedlease} refreshData={prop.refreshData} />
            <AddVariablePayment type={'card'} hitApi={prop.refreshData} lease_id={selectedlease} variablePaymentOpen={variablePaymentOpen} toggleVariablePayment={toggleVariablePayment} setVariablePaymentOpen={setVariablePaymentOpen} variablePayments={variablePaymentArr} setVariablePayments={setVariablePaymentArr} />
        </div>
    );
}