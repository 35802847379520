import React, { useState, useEffect, useContext } from "react"
import { Row, Col,CardBody} from "reactstrap"
import {Helmet} from "react-helmet"
import ClientListView from "../../components/client/list"
import ClientGridView from "../../components/client/grid"
import BreadcrumbManual from "../../components/Common/BreadcrumbManual"
import "./datatables.scss"
import "../../assets/scss/manual/manual.scss"
import axios from "axios";
import AppConfig from "constants/config";
import toast from 'react-hot-toast';
import { AuthContext } from "context/authContext"
import Layout from "components/VerticalLayout"

const MyClients = () => {
  const [isListView, setIsListView] = useState(true);
  const [clientData, setClientsData] = useState([]);
  const [leaseData, setLeaseData] = useState([]);
  const { userData,mode } = useContext(AuthContext)
  const [loading, setLoading] = useState(true);

  const handleViewChange = () => {
    setIsListView(!isListView);
  }

  const getClientsData = async(filter) =>{
    setLeaseData([]);
    setClientsData([]);
    setLoading(true);
    try {
      const {data} = await axios.get(`${AppConfig.baseUrl}/user_client/get_users_client?user_id=${userData.id}&access_type=${filter.access_type || ""}&search=${filter.search || ""}&client_type=${mode || "lessee"}&mode=${mode ? mode : 'lessee'}`,{
        headers: { token: localStorage.getItem("token") }
      });
      if(!data.error){
        setLeaseData(data.leaseDataByClient || []);
        setClientsData(data.clientData || []);
      }
      else{
          throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong');
    }
    setLoading(false)
  }

  useEffect(() => {
    if(userData && userData.id){
      getClientsData({});
    }
  }, [userData, mode])

  return (
    <Layout>
      <div className="page-content">
        <Helmet>
          <title>LeaseVista | My Clients</title>
        </Helmet>
        <div className="container-fluid">
          <BreadcrumbManual title="Tables" breadcrumbItem="my clients" type='client' handleViewChange={handleViewChange} getClientsData={getClientsData} />
          <Row>
            <Col className="col-12">
                {
                  isListView ?
                    <ClientListView clientData={clientData} leaseData={leaseData} getClientsData={getClientsData} loading={loading}/>
                  :
                   <CardBody className="p-0"> 
                    <ClientGridView clientData={clientData} getClientsData={getClientsData} loading={loading}/> 
                  </CardBody>
                }
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  )
}

export default MyClients
