import React, { useState,useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import logoSmall from "../../assets/images/logos/logo-small.png";
import { AuthContext } from 'context/authContext';
import { UncontrolledAlert } from 'reactstrap';
import toast from 'react-hot-toast';
import axios from "axios";
import AppConfig from "../../constants/config";

const Header = props => {
  const [search, setsearch] = useState(false);
  const {userData,mode,changeMode,changeVersion, setChangeVersion,version} = useContext(AuthContext)
  const [custData, setCustData] = useState({})

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }
  const handleChangeMode= (e) => {
    changeMode(e.target.value)
    localStorage.setItem("mode", e.target.value);
  }
  const reloadWebsite = () =>{
    localStorage.setItem("version",version)
    caches.keys().then((names) =>{
    	names.forEach((names)=>{
    		caches.delete(names)
    	})
    })
    window.location.reload()
    setChangeVersion(false)
  }

  const getCustomerData = async() => {
    try {
        const id = userData.cust_info._id;
        const {data} = await axios.get(`${AppConfig.baseUrl}/customer/getCustomerData?id=${id}`,{
            headers: { token: localStorage.getItem("token") }
        });
        if(!data.error){
            setCustData(data.custData)
        }
        else{
            throw new Error(data.title);
        }
    } catch (error) {
      toast.error(error.message || 'Something went wrong')
    }
  }
  useEffect(() => {
    if(userData && userData.cust_info && userData.cust_info._id){
        getCustomerData()
    }
  },[userData])
  
  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">

            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoSmall} alt="" height="40" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>

            {
              custData && custData.modules && custData.modules.includes('lessor_gasb') &&
              <div className="mt-3 me-2">
                <select
                  value={mode}
                  onChange={handleChangeMode}
                  id="formrow-InputState"
                  style = {{width:'120px'}}
                  className="form-control form-control-md arrows"
                >
                  <option name="lessee" value="lessee">Lessee Mode</option>
                  <option name="lessor" value="lessor">Lessor Mode (experiment)</option>
                </select>
              </div>
            }
          </div>
          {
            (userData.payment_overdue === true && userData.main_user_type != 'third_party') && 
            <div style={{width:'75%'}} className="mt-3 me-1">
              <UncontrolledAlert
                color="danger"
                className="alert-dismissible fade show"
                role="alert"
              >
                <i className="mdi mdi-alert-outline me-2"></i>Your payment for LeaseVista is overdue, please make the payment as soon as possible to avoid potential disruption to your service.
              </UncontrolledAlert>
            </div>
          }
          {
            changeVersion && 
            <div style={{width:(userData.payment_overdue === true && userData.main_user_type != 'third_party') ? '55%' : '75%'}} className="mt-3">
              <UncontrolledAlert
                color="warning"
                className="alert-dismissible fade show"
                role="alert"
                onClose = {reloadWebsite}
                onClick = {reloadWebsite}
              >
                <i className="mdi mdi-alert-outline me-2"></i>We have updated our website please click here to refresh or you can manually refresh.
              </UncontrolledAlert>
            </div>
          }
          <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-lbusers" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
