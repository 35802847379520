/* eslint-disable react/no-unknown-property */
import React, { useState, useEffect, useContext } from "react"
import {Helmet} from "react-helmet";
import { capitalize, map } from "lodash"
import { Card, CardBody, CardTitle, Col, Container, Row, Table, DropdownToggle, DropdownMenu, ButtonDropdown, DropdownItem, Spinner } from "reactstrap"
import { NavLink, useNavigate,useLocation } from "react-router-dom";
import Breadcrumbs from "components/Common/Breadcrumbclientdetails"
import MiniCards from "./mini-card"
import DeleteLease from "pages/Leases/deleteLease";
import TerminateLease from "pages/Leases/terminateLease";
import AppConfig from "constants/config";
import toast from "react-hot-toast";
import axios from "axios";
import { AuthContext } from "context/authContext";
import moment from "moment";
import AddClientToLease from "pages/Leases/addClientToLease";
import ModifyDate from "pages/Leases/modifyDate";
import RevertLease from "pages/Leases/revertLease";
import LeaseName from "components/client/leaseName";
import Layout from "components/VerticalLayout";

const ClientDetails = () => {
  const { userData, mode } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [clientDetails, setClientDetails] = useState({});
  const [id, setId] = useState('');
  const [selectedLease, setSelectedLease] = useState({})
  const [leaseId, setLeaseId] = useState('')
  const [deleteLease, setDeleteLease] = useState(false)
  const [terminateLease, setTerminateLease] = useState(false)
  const [viewBtn, setViewBtn] = useState(false)
  const [accessType, setAccessType] = useState('')
  const [totatLeases, setTotalLeases] = useState(0)
  const [recentLeaseData, setRecentLeaseData] = useState([])
  const [endingLeaseData, setEndingLeaseData] = useState([])
  const [loading, setLoading] = useState(false)
  const [leaseData, setLeaseData] = useState('')
  const [addClientLeasePopup, setAddClientLeasePopup] = useState(false)
  const [modifyOpen, setModifyOpen] = useState(false);
  const [revertLease, setRevertLease] = useState(false)
  const [leaseCountData, setLeaseCountData] = useState({})
  const [termiDate, setTermiDate] = useState('');
  const [loadingTerminate, setLoadingTerminate] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [loadingRevert, setLoadingRevert] = useState(false)

  const AddClientLeaseToggle = () => {
    setAddClientLeasePopup(!addClientLeasePopup)
  }
  const [miniCards, setMiniCards] = useState([
    { title: "Access type", iconClass: "bx-check", text: "Full Access" },
    { title: "Total leases", iconClass: "bx-file", text: "31" },
    { title: "Last activity", iconClass: "bx-time", text: "11/11/2021" },
  ]);

  useEffect(() => {
    setId((location.search).replace('?', ''));
  }, [])

  useEffect(() => {
    if (id) {
      getClientDetails(id);
    }
    if (id && userData.id) {
      getUserClientDetails(id)
      getRecentLeases(id);
      getEndingLeases(id);
    }
  }, [id, userData])

  const getClientDetails = async (client_id) => {
    try {
      const { data } = await axios.get(`${AppConfig.baseUrl}/client/details?id=${client_id}&mode=${mode ? mode : 'lessee'}`, {
        headers: { token: localStorage.getItem("token") }
      });
      if (!data.error) {
        const total = data.leaseData ? Number(data.leaseData.activeLeases || 0) + ((Number(data.leaseData.terminatedLeases || 0) + Number(data.leaseData.expiredLeases || 0) + Number(data.leaseData.draftedLeases || 0)) / 4) : 0
        setTotalLeases(total || 0)
        setLeaseCountData(data.leaseData)
        setClientDetails(data.clientDetails)
      }
      else {
        toast.error(data.title);
        navigate(`/${localStorage.getItem('company')}/clients`);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong')
    }
  }

  const getRecentLeases = async (client_id) => {
    setLoading(true)
    try {
      const { data } = await axios.get(`${AppConfig.baseUrl}/lease/recent_leases?client_id=${client_id}&user_id=${userData.id}&mode=${mode ? mode : 'lessee'}`, {
        headers: { token: localStorage.getItem("token") }
      });
      if (!data.error) {
        setRecentLeaseData(data.data)
      }
      else {
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong')
    }
    setLoading(false)
  }

  const getEndingLeases = async (client_id) => {
    try {
      const { data } = await axios.get(`${AppConfig.baseUrl}/lease/ending_leases?client_id=${client_id}&user_id=${userData.id}&mode=${mode ? mode : 'lessee'}`, {
        headers: { token: localStorage.getItem("token") }
      });
      if (!data.error) {
        setEndingLeaseData(data.data)
      }
      else {
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong')
    }
  }

  const getUserClientDetails = async (client_id) => {
    try {
      const { data } = await axios.get(`${AppConfig.baseUrl}/user_client/details?client_id=${client_id}&user_id=${userData.id}`, {
        headers: { token: localStorage.getItem("token") }
      });
      if (!data.error) {
        const access = data && data.userData && data.userData.access_type ? (data.userData.access_type).replace("_", " ").toUpperCase() : ''
        setAccessType(access)
      }
      else {
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong')
    }
  }

  // eslint-disable-next-line no-unused-vars
  const toggleDeleteLease = () => {
    setDeleteLease(!deleteLease)
  }

  const toggleTerminateLease = () => {
    setTerminateLease(!terminateLease)
  }

  const toggleRevertLease = () => {
    setRevertLease(!revertLease)
  }

  useEffect(() => {
    if (clientDetails) {
      setMiniCards([
        { title: "Access type", iconClass: "bx-check", text: accessType || 'NA' },
        {
          title: "Total leases", iconClass: "bx-file", text:
            <div
              className="table-data-margin"
              tabIndex="0" dataToggle="tooltip"
              title={`Active Leases: ${leaseCountData.activeLeases || 0} \nTerminated Leases: ${leaseCountData.terminatedLeases || 0} \nInactive Leases: ${leaseCountData.expiredLeases || 0} \nDrafted Leases: ${leaseCountData.draftedLeases || 0}`}
            >
              {totatLeases}
            </div>
        },
        { title: "Activity log", iconClass: "bx-time", text: <NavLink className="text-lbusers text-decoration-underline" to={`/${localStorage.getItem('company')}/clients/activitylog/${id}`}>View</NavLink> }
      ])
    }
  }, [clientDetails, accessType, totatLeases, leaseCountData])

  const getProgressPercentage = (data) => {
    let today = moment(new Date(), 'M/D/YYYY')
    let start = moment(new Date(data.commencement), 'M/D/YYYY')
    let end = moment(new Date(data.terminal_end_date), 'M/D/YYYY')
    let startEndDiff = end.diff(start, 'days');
    let todayEndDiff = end.diff(today, 'days');
    let per = ((startEndDiff - todayEndDiff) / startEndDiff) * 100;
    return Math.round(per)
  }

  const deleteL = async () => {
    setLoadingDelete(true)
    try {
      const { data } = await axios.post(`${AppConfig.baseUrl}/lease/delete_lease`, { Id: leaseId._id, mode: mode ? mode : 'lessee' }, {
        headers: { token: localStorage.getItem("token") }
      });
      if (!data.error) {
        toast.success(data.title || 'Lease deleted successfully')
        setLeaseId('')
        toggleDeleteLease();
        if (id && userData.id) {
          getRecentLeases(id);
          getEndingLeases(id);
        }
      }
      else {
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong')
    }
    setLoadingDelete(false)
  }

  const toggleModifyLease = () => {
    setModifyOpen(!modifyOpen)
  }

  const modifyL = (data) => {
    let a = new Date(data.date)
    window.location.href =`/${localStorage.getItem('company')}/leases/modify?lease_id=${leaseId._id}&client_id=${clientDetails._id}&m_date=${data.date}&mod_type=${data.mod_type}&percentage=${data.percentage}`
  }

  const revertL = async () => {
    setLoadingRevert(true)
    try {
      const { data } = await axios.post(`${AppConfig.baseUrl}/lease_draft/revertLease`, { lease_id: leaseId._id, mode: mode ? mode : 'lessee', type:'revert' }, {
        headers: { token: localStorage.getItem("token") }
      });
      if (!data.error) {
        toast.success(data.title || 'Lease reverted successfully')
        setLeaseId('')
        if (id && userData.id) {
          getRecentLeases(id);
        }
      }
      else {
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong')
    }
    toggleRevertLease();
    setLoadingRevert(false)
  }

  const terminateL = async (d) => {
    setLoadingTerminate(true)
    try {
      let newDate = moment(d.date).format('YYYY-MM-DD');
      let amt = d.check === true ? Number(d.amount) : 0;
      const { data } = await axios.post(`${AppConfig.baseUrl}/lease/terminate_lease`, { lease_id: d.lease_id, date: newDate, amount: amt, mode: mode ? mode : 'lessee' }, {
        headers: { token: localStorage.getItem("token") }
      });
      if (!data.error) {
        toast.success(data.title || 'Lease terminated successfully')
        navigate.push(`/${localStorage.getItem('company')}/leases/details/${data.lease_id}/refresh`);
      }
      else{
        throw new Error(data.title);
      }
    } catch (error) {
      toast.error(error.message || 'Something went wrong')
    }
    toggleTerminateLease()
    setLoadingTerminate(false)
  }

  useEffect(() => {
    if (mode && clientDetails && clientDetails.client_type && mode != clientDetails.client_type) {
      window.location.href = `/${localStorage.getItem('company')}/clients`
    }
  }, [mode])

  useEffect(() => {
    if (leaseId) {
        setTermiDate(moment(leaseId.commencement).utc().add(1, 'day').format('MM/DD/YYYY'));
    }        
  },[leaseId])

  return (
    <Layout>
      <div className="page-content">
        <Helmet>
          <title>LeaseVista | Client Details</title>
        </Helmet>
        <Container fluid>
          <Breadcrumbs title="clientdetail" breadcrumbItem="" name={clientDetails ? clientDetails.client : ''} img={clientDetails ? clientDetails.logo : ''} type='clientDetails' />
          <Row>
            <Col xl="4">
              <Card className="overflow-hidden">
                <CardBody>
                  <CardTitle className="mb-4">OVERVIEW</CardTitle>
                  <div className="table-responsive">
                    <Table className="table-nowrap mb-0">
                      <tbody>
                        <tr>
                          <th scope="row">Name :</th>
                          <td>{clientDetails ? clientDetails.client : ''}</td>
                        </tr>
                        <tr>
                          <th scope="row">Contact name:</th>
                          <td>{clientDetails ? clientDetails.contact_person : ''}</td>
                        </tr>
                        <tr>
                          <th scope="row">E-mail :</th>
                          <td>{clientDetails ? clientDetails.email : ''}</td>
                        </tr>
                        <tr>
                          <th scope="row">Address</th>
                          <td>{clientDetails ? clientDetails.address : ''}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <div className="d-flex flex-wrap">
                    <div className="ms-2">
                      <h5 className="card-title mb-3">LEASES ENDING SOON</h5>
                    </div>
                  </div>
                  <div>
                    <ul className="list-group list-group-flush">
                      {
                        !endingLeaseData || endingLeaseData.length < 1 ? <div className="ms-2">No data found</div> :
                          endingLeaseData.map(lease => {
                            const progress = getProgressPercentage(lease);
                            return (<li className="list-group-item" key={lease._id}>
                              <div>
                                <h5 className="font-size-14">
                                  {lease.name}
                                </h5>
                                <div className="progress animated-progess progress-sm">
                                  <div
                                    className={`progress-bar ${progress <= 30 ? 'bg-success' : (progress > 30 && progress <= 60) ? 'bg-warning' : progress > 60 ? 'bg-danger' : ''}`}
                                    role="progressbar"
                                    style={{ width: `${progress}%` }}
                                    aria-valuenow={progress}
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                                <h5 className="font-size-14 py-2">
                                  <span className="float-start">{moment(lease.commencement, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                                  <span className="float-end">{moment(lease.terminal_end_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</span>
                                </h5>
                              </div>
                            </li>
                            )
                          })
                      }
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col xl="8">
              <Row>
                {map(miniCards, (card, key) => (
                  <MiniCards
                    title={card.title}
                    text={card.text}
                    iconClass={card.iconClass}
                    key={"_card_" + key}
                  />
                ))}
              </Row>
              <Card>
                <CardBody>
                  <CardTitle className="mb-4">RECENT LEASES
                    {
                      accessType !== 'READ ONLY' &&
                      <div className=" d-flex dataTables_filter">
                        <NavLink to={`/${localStorage.getItem('company')}/leases/add/${id}`}>
                          <button type="button" className="btn btn-lbusers dataTables_filter">
                            ADD NEW
                          </button>
                        </NavLink>
                      </div>
                    }
                  </CardTitle>
                  <div className="table-responsive table-height">
                    <Table className="table mb-0">
                      <thead className="table-light">
                        <tr>
                          <th>Lease</th>
                          <th>Start date</th>
                          <th>End date</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          loading ?
                            <tr><td colSpan={4} style={{ textAlign: "center" }}><Spinner className="text-lbusers" /></td></tr>
                            : !recentLeaseData || recentLeaseData.length < 1 ? <tr><td colSpan={4} style={{ textAlign: "center" }}>No data found</td></tr> :
                              recentLeaseData && recentLeaseData.map((lease, key) => {
                                return <tr key={key} style={{ backgroundColor: lease && lease.is_processing ? '#faf1d7' : '#fff' }}>
                                  <td scope="row">
                                    {
                                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div className="avatar-sm">
                                          <button
                                            type="button"
                                            className="btn rounded-circle avatar-sm bg-lbusers mt-0 ms-0 text-align-center"
                                          >
                                            {lease.type === 'Headquarters' ? <i className='bx bx-buildings text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                                              : lease.type === 'Super Car' ? <i className='bx bxs-car text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                                                : lease.type === 'Trade Center' ? <i className='bx bxs-building-house text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                                                  : lease.type === 'Vehicle' ? <i className='bx bxs-car text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                                                    : (lease.type === 'Building' || lease.type === 'Office') ? <i className='bx bxs-buildings text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                                                      : lease.type === 'Land' ? <i className='bx bxs-landscape text-white' style={{ fontSize: '150%', padding: '0' }}></i>
                                                        : lease.type === 'Equipment' ? <i className='bx bx-list-ol text-white' style={{ fontSize: '180%', padding: '0' }}></i>
                                                          : lease.type === 'Others' ? <i className='bx bxs-customize text-white' style={{ fontSize: '150%', padding: '0' }}></i> : ''
                                            }
                                          </button>
                                        </div>
                                        <LeaseName lease={lease} index={key} />
                                      </div>
                                    }
                                  </td>
                                  <td><div className="mt-3">{moment(lease.commencement, 'YYYY-MM-DD').format('MM/DD/YYYY')}</div></td>
                                  <td><div className="mt-3">{moment(lease.terminal_end_date, 'YYYY-MM-DD').format('MM/DD/YYYY')}</div></td>
                                  <td><div className="mt-3">{lease.status ? capitalize(lease.status) : "Active"}</div></td>
                                  <td>
                                    <div className={lease && lease.is_processing ? "mt-3" : "mt-2"}>
                                      {
                                        lease && lease.is_processing ? "Processing" :
                                          <ButtonDropdown
                                            key={key}
                                            isOpen={selectedLease === lease._id}
                                            toggle={() => {
                                              if (!viewBtn) {
                                                setSelectedLease(lease._id)
                                              }
                                              else {
                                                setSelectedLease({})
                                              }
                                              setViewBtn(!viewBtn);
                                            }}

                                          >
                                            <DropdownToggle
                                              caret
                                              color="lbusers"
                                              className="btn btn-primary btn-sm"
                                            >
                                              Actions &nbsp;{" "}
                                              <i className="mdi mdi-chevron-down" />
                                            </DropdownToggle>
                                            <DropdownMenu right>
                                              {lease.audit_category !== 'drafted' && <DropdownItem href={`/${localStorage.getItem('company')}/leases/details/${lease._id}/other`}> View</DropdownItem>}
                                              {
                                                accessType == 'FULL ACCESS' && <>
                                                  { (!lease.is_terminated) && <DropdownItem href={lease.is_modified ? `/${localStorage.getItem('company')}/leases/edit_modify_lease?lease_id=${lease._id}&client_id=${lease.client_id}` : `/${localStorage.getItem('company')}/leases/edit?lease_id=${lease._id}&client_id=${lease.client_id}`}> Edit</DropdownItem>} 
                                                  {(lease.is_modified || lease.is_terminated) && lease.audit_category !== 'drafted' && <DropdownItem onClick={() => { setRevertLease(true); setLeaseId(lease) }}> Revert</DropdownItem>}
                                                  {!lease.is_terminated && lease.audit_category !== 'drafted' && <DropdownItem onClick={() => { setModifyOpen(true); setLeaseId(lease); }}> Modify (GAAP)</DropdownItem>}
                                                  {(!lease.is_modified && !lease.is_terminated && lease.audit_category !== 'drafted') && <DropdownItem onClick={() => { setAddClientLeasePopup(true); setLeaseData(lease) }}> Clone</DropdownItem>}
                                                  {!lease.is_terminated && lease.audit_category !== 'drafted' && <DropdownItem onClick={() => { setTerminateLease(true); setLeaseId(lease); }}> Terminate</DropdownItem>}
                                                  <DropdownItem onClick={() => { setDeleteLease(true); setLeaseId(lease) }}> Delete</DropdownItem>
                                                </>
                                              }
                                            </DropdownMenu>
                                          </ButtonDropdown>
                                      }
                                    </div>
                                  </td>
                                </tr>
                              })}
                      </tbody>
                    </Table>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <DeleteLease loading={loadingDelete} deleteOpen={deleteLease} toggleDelete={toggleDeleteLease} setDeleteOpen={setDeleteLease} lease={selectedLease} deleteL={deleteL} />
      <ModifyDate modifyOpen={modifyOpen} toggleModify={toggleModifyLease} setModifyOpen={setModifyOpen} lease={leaseId} modifyL={modifyL} termiDate={termiDate} />
      <RevertLease loading={loadingRevert} deleteOpen={revertLease} toggleDelete={toggleRevertLease} setDeleteOpen={setRevertLease} lease={selectedLease} deleteL={revertL} />
      <TerminateLease loading={loadingTerminate} terminateOpen={terminateLease} toggleTerminateLease={toggleTerminateLease} setTerminateOpen={setTerminateLease} termiDate={termiDate} setTermiDate={setTermiDate} modifyOpen={modifyOpen} toggleModify={toggleModifyLease} setModifyOpen={setModifyOpen} lease={leaseId} terminateL={terminateL} modifyL={modifyL} />
      {addClientLeasePopup &&
        <AddClientToLease addOpen={addClientLeasePopup} toggleAdd={AddClientLeaseToggle} setOpenPopup={setAddClientLeasePopup} type='clone' lease_id={leaseData._id} />
      }
    </Layout>
  )
}


export default ClientDetails

