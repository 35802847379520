import React, { useState, useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import {
    Card,
    CardBody,
    Col,
    Container,
    Form,
    InputGroup,
    Input,
    Label,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
} from "reactstrap"
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import moment from 'moment';
import img from "assets/images/product/img-1.png"

const AddLeaseStepFive = (prop) => {
    let { formik, mode } = prop;

    const checkDays = (e) => {
        formik.setFieldValue(`usefulEconomicLife`, (e.target.value))
        let start = moment(new Date(formik.values.commencementDate)).endOf('day').format()
        let end = moment(formik.values.terminalLeaseEndDate)
        let days = end.diff(start, 'days');
        let newDate = moment(new Date(formik.values.commencementDate)).add(Number(e.target.value), formik.values.typeStep5);
        let economicDay = newDate.diff(start, 'days');
        let per = (days / economicDay) * 100;
        if(Number(per) >= 75 && e.target.value && e.target.value > 0){
            formik.setFieldValue("economicLifeOfUnderlyingAsset", true)
        } else {
            formik.setFieldValue("economicLifeOfUnderlyingAsset", false)
        }
    }
    useEffect(() => {
        if(formik.values.classify == 'operating'){
            formik.setFieldValue("typeStep5", 'months')
            formik.setFieldValue("usefulEconomicLife", '')
            formik.setFieldValue("fairValue", '')
            formik.setFieldValue("specializedNature", false)
            formik.setFieldValue("economicLifeOfUnderlyingAsset", false)
            formik.setFieldValue("substantiallyAllOfTheFairValue", false)
            formik.setFieldValue("transferOwnership", false)
        }
        if(formik.values.classify == 'financing'){
            formik.setFieldValue("specializedNature", false)
            formik.setFieldValue("economicLifeOfUnderlyingAsset", false)
            formik.setFieldValue("substantiallyAllOfTheFairValue", false)
            formik.setFieldValue("fairValue", '')
        } 
    },[formik.values.classify])
    return (
        <React.Fragment>
            <Form>
                <Row>{mode != 'lessor' && 
                    <Col md="12">
                        <div className="d-flex">
                        <h6 className="mt-4">
                            How would you classify this lease? <span className="text-danger">*</span>
                        </h6>
                        <div className="form-check mt-4 mb-4 ms-3">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="classify"
                                id="operating"
                                value="operating"
                                disabled={formik.values.paymentsForPurchaseReason || formik.values.reporting_standard == "GASB 87" || formik.values.reporting_standard == "IFRS 16"}
                                onChange={ formik.handleChange }
                                onBlur={() => formik.setFieldTouched("classify")}
                                checked={formik.values.classify == 'operating'}
                            />      
                            <label
                                className="form-check-label"
                                htmlFor="operating"
                            >
                                Operating
                            </label>
                        </div>
                        <div className="form-check mt-4 ms-3">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="classify"
                                id="financing"
                                value="financing"
                                disabled={ formik.values.is_short_term }
                                onChange={ formik.handleChange }
                                onBlur={() => formik.setFieldTouched("classify")}
                                checked={formik.values.classify == 'financing'}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="financing"
                            >
                                Financing
                            </label>
                        </div>
                        <div className="form-check mt-4 ms-3">
                            <input
                                className="form-check-input"
                                type="radio"
                                name="classify"
                                id="determine"
                                value="determine"
                                disabled={formik.values.reporting_standard == "GASB 87" || formik.values.reporting_standard == "IFRS 16" || formik.values.is_short_term}
                                onChange={ formik.handleChange }
                                onBlur={() => formik.setFieldTouched("classify")}
                                checked={formik.values.classify == 'determine'}
                            />
                            <label
                                className="form-check-label"
                                htmlFor="determine"
                            >
                                Use LeaseVista to determine
                            </label>
                        </div>
                    </div>
                    </Col>
                }</Row>
                {
                    formik.values.classify === 'financing' ?
                        <React.Fragment>
                            <Row>
                                <Col md="12">
                                    <div className="d-flex mt-4">
                                        <h6>
                                            Does lease transfer ownership of underlying asset to lessee by end of lease term?
                                        </h6>
                                        <div className="form-check form-switch ms-3">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="transferOwnership"
                                                defaultChecked={formik.values.transferOwnership}
                                                onChange={(e)=> {
                                                    formik.setFieldValue(`transferOwnership`, e.target.checked)
                                                }}
                                                onBlur={() => formik.setFieldTouched("transferOwnership")}
                                            />
                                            {formik.touched.transferOwnership && formik.errors.transferOwnership && (
                                                <span className='text-danger'>{formik.touched.transferOwnership && formik.errors.transferOwnership}</span>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <div className="d-flex mt-4">
                                        <h6>
                                            Does lease grant lessee an option to purchase underlying asset that lessee Is reasonably certain to exercise?
                                        </h6>
                                        <div className="form-check form-switch ms-3">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="certainToExercise"
                                                name="certainToExercise"
                                                checked={formik.values.certainToExercise}
                                                onChange={(e) =>{ 
                                                    formik.setFieldValue(`certainToExercise`, (e.target.checked))
                                                    formik.setFieldValue(`paymentsForPurchaseReason`, (e.target.checked))
                                                    let elem = document.getElementById(`paymentsForPurchaseReason`);
                                                    if(e.target.checked === false){
                                                        elem.checked = false;
                                                    } else {
                                                        elem.checked = true;
                                                    }
                                                }}
                                                onBlur={() => formik.setFieldTouched("certainToExercise")}
                                                // value={formik.values.certainToExercise}
                                            />
                                            {formik.touched.certainToExercise && formik.errors.certainToExercise && (
                                                <span className='text-danger'>{formik.touched.certainToExercise && formik.errors.certainToExercise}</span>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <div className="mt-4">
                                        <Label for="optAmount">
                                            Amount for option to purchase
                                        </Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="amountForOptionToPurchase"
                                            onChange={(e) =>{
                                                formik.setFieldValue(`paymentsForPurchaseAmount`, (e.target.value))
                                                formik.setFieldValue(`amountForOptionToPurchase`, (e.target.value))
                                            }}
                                            onBlur={() => formik.setFieldTouched("amountForOptionToPurchase")}
                                            value={Number(formik.values.paymentsForPurchaseAmount || 0) > 0 ? formik.values.paymentsForPurchaseAmount : formik.values.amountForOptionToPurchase}
                                            />
                                            {formik.touched.amountForOptionToPurchase && formik.errors.amountForOptionToPurchase && (
                                                <span className='text-danger'>{formik.touched.amountForOptionToPurchase && formik.errors.amountForOptionToPurchase}</span>
                                            )}
                                    </div>
                                </Col>
                                <Col md="6">
                                    <div className="mt-4">
                                        <Label for="Receipt date">
                                            Payment Date
                                        </Label>
                                        <InputGroup>
                                            <Flatpickr
                                                // value={Date.parse(pick_date)}
                                                className="form-control d-block bg-white"
                                                options={{
                                                    altInput: true,
                                                    altFormat: "m/d/Y",
                                                    dateFormat: "m/d/Y",
                                                    allowInput: true
                                                }}
                                                id='paymentsForPurchasePaymentDate'
                                                // value={prop.type === 'Edit' || prop.type === 'clone' ? "03/23/2022":''}
                                                value={ 
                                                    formik.values.paymentsForPurchasePaymentDate instanceof Array ?
                                                    moment(new Date(formik.values.paymentsForPurchasePaymentDate[0])).format('MM/DD/YYYY') :
                                                    moment(new Date(formik.values.paymentsForPurchasePaymentDate)).format('MM/DD/YYYY')
                                                }
                                                onClose={(e) =>{
                                                    formik.setFieldValue(`paymentsForPurchasePaymentDate`, (e))
                                                }}
                                            />
                                            <div className="input-group-append">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary docs-datepicker-trigger"
                                                    disabled
                                                >
                                                    <i
                                                        className="fa fa-calendar"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            </div>
                                        </InputGroup>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6">
                                    <div className="mt-4">
                                        <Label for="ecoLife">
                                            Useful economic life
                                        </Label>
                                        <InputGroup>
                                            <div className="input-group-append" style={{width:'81px'}}>
                                                <select className="form-control" id='type'  onChange={(e)=>{
                                                    formik.setFieldValue(`typeStep5`, e.target.value)
                                                }}
                                                value={formik.values.typeStep5} onBlur={() => formik.setFieldTouched("typeStep5")}>
                                                    {/* <option value='days'>Days</option>
                                                    <option value='weeks'>Weeks</option> */}
                                                    <option value='months'>Months</option>
                                                    {/* <option value='years'>Years</option> */}
                                                </select>
                                            </div>
                                            <Input
                                                type="number"
                                                className="form-control"
                                                id="usefulEconomicLife"
                                                onChange={(e) =>{
                                                    formik.setFieldValue(`usefulEconomicLife`, (e.target.value))
                                                }}
                                                onBlur={() => formik.setFieldTouched("usefulEconomicLife")}
                                                value={formik.values.usefulEconomicLife}
                                            />
                                        </InputGroup>
                                    </div>
                                    {formik.errors && formik.errors.usefulEconomicLife && (
                                        <span className='text-danger'>{formik.errors.usefulEconomicLife}</span>
                                    )}
                                </Col>
                            </Row>
                        </React.Fragment>
                        : formik.values.classify === 'determine' ?
                            <React.Fragment>
                                <Row>
                                    <Col md="12">
                                    <div className="d-flex mt-4">
                                        <h6>
                                            Does lease transfer ownership of underlying asset to lessee by end of lease term?
                                        </h6>
                                        <div className="form-check form-switch ms-3">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="transferOwnership"
                                                onChange={(e)=> {
                                                    formik.setFieldValue(`transferOwnership`, (e.target.checked))
                                                }}
                                                onBlur={() => formik.setFieldTouched("transferOwnership")}
                                                defaultChecked={formik.values.transferOwnership}
                                            />
                                            {formik.touched.transferOwnership && formik.errors.transferOwnership && (
                                                <span className='text-danger'>{formik.touched.transferOwnership && formik.errors.transferOwnership}</span>
                                            )}
                                        </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="10">
                                    <div className="d-flex mt-4">
                                        <h6>
                                            Does lease grant lessee an option to purchase underlying asset that lessee Is reasonably certain to exercise?
                                        </h6>
                                        <div className="form-check form-switch ms-3">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="certainToExercise"
                                                name="certainToExercise"
                                                defaultChecked={formik.values.certainToExercise}
                                                onChange={(e)=> {
                                                    formik.setFieldValue(`certainToExercise`, (e.target.checked))
                                                    formik.setFieldValue(`paymentsForPurchaseReason`, (e.target.checked))
                                                    let elem = document.getElementById(`paymentsForPurchaseReason`);
                                                    if(e.target.checked === false){
                                                        elem.checked = false;
                                                    } else {
                                                        elem.checked = true;
                                                    }
                                                }}
                                                onBlur={() => formik.setFieldTouched("certainToExercise")}
                                                // value={formik.values.certainToExercise}
                                            />
                                            {formik.touched.certainToExercise && formik.errors.certainToExercise && (
                                                <span className='text-danger'>{formik.touched.certainToExercise && formik.errors.certainToExercise}</span>
                                            )}
                                        </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className="mt-4">
                                            <Label for="optAmount">
                                                Amount for option to purchase
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                // disabled
                                                // value="$20000"
                                                id="amountForOptionToPurchase"
                                                onChange={(e)=> {
                                                    formik.setFieldValue(`amountForOptionToPurchase`, (e.target.value))
                                                    formik.setFieldValue(`paymentsForPurchaseAmount`, (e.target.value))
                                                }}
                                                onBlur={() => formik.setFieldTouched("amountForOptionToPurchase")}
                                                value={formik.values.paymentsForPurchaseAmount}
                                            />
                                            {formik.touched.amountForOptionToPurchase && formik.errors.amountForOptionToPurchase && (
                                                <span className='text-danger'>{formik.touched.amountForOptionToPurchase && formik.errors.amountForOptionToPurchase}</span>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                    <div className="mt-4">
                                        <Label for="Receipt date">
                                            Payment Date
                                        </Label>
                                        <InputGroup>
                                            <Flatpickr
                                                className="form-control d-block bg-white"
                                                options={{
                                                    altInput: true,
                                                    altFormat: "m/d/Y",
                                                    dateFormat: "m/d/Y",
                                                    minDate: moment(new Date(formik.values.commencementDate)).format('MM/DD/YYYY'),
                                                    allowInput: true
                                                }}
                                                id='paymentsForPurchasePaymentDate'
                                                // value={prop.type === 'Edit' || prop.type === 'clone' ? "03/23/2022":''}
                                                value={ 
                                                    formik.values.paymentsForPurchasePaymentDate instanceof Array ?
                                                    moment(new Date(formik.values.paymentsForPurchasePaymentDate[0])).format('MM/DD/YYYY') :
                                                    moment(new Date(formik.values.paymentsForPurchasePaymentDate)).format('MM/DD/YYYY')
                                                }
                                                onClose={(e) =>{
                                                    formik.setFieldValue(`paymentsForPurchasePaymentDate`, (e))
                                                }}
                                            />
                                            <div className="input-group-append">
                                                <button
                                                    type="button"
                                                    className="btn btn-outline-secondary docs-datepicker-trigger"
                                                    disabled
                                                >
                                                    <i
                                                        className="fa fa-calendar"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            </div>
                                        </InputGroup>
                                    </div>
                                </Col>
                                </Row>
                                <Row>
                                <Col md="12">
                                    <div className="d-flex mt-5">
                                        <h6>
                                            Underlying asset of such a specialized nature that it is expected to have no alternative use to the lessor?
                                        </h6>
                                        <div className="form-check form-switch ms-3">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="specializedNature"
                                                onChange={(e)=> {
                                                    formik.setFieldValue(`specializedNature`, (e.target.checked))
                                                }}
                                                onBlur={() => formik.setFieldTouched("specializedNature")}
                                                defaultChecked={formik.values.specializedNature}
                                                />
                                                {formik.touched.specializedNature && formik.errors.specializedNature && (
                                                    <span className='text-danger'>{formik.touched.specializedNature && formik.errors.specializedNature}</span>
                                                )}
                                        </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6">
                                        <div className="mt-4">
                                            <Label for="ecoLife">
                                                Useful economic life
                                            </Label>
                                            <InputGroup>
                                            <div className="input-group-append" style={{width:'81px'}}>
                                                <select className="form-control" id='type'  onChange={(e)=>{
                                                    formik.setFieldValue(`typeStep5`, e.target.value)
                                                }}
                                                value={formik.values.typeStep5} onBlur={() => formik.setFieldTouched("typeStep5")}>
                                                    <option value='months'>Months</option>
                                                </select>
                                            </div>
                                            <Input
                                                    type="number"
                                                    className="form-control"
                                                    id="usefulEconomicLife"
                                                    onChange={checkDays}
                                                    onBlur={() => formik.setFieldTouched("usefulEconomicLife")}
                                                    value={formik.values.usefulEconomicLife}
                                                />
                                            </InputGroup>
                                        </div>
                                        {formik.errors && formik.errors.usefulEconomicLife && (
                                            <span className='text-danger'>{formik.errors.usefulEconomicLife}</span>
                                        )}
                                    </Col>
                                    <Col md="6">
                                        <div className="mt-4">
                                            <Label for="fairValue">
                                                Fair value
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="fairValue"
                                                onChange={formik.handleChange}
                                                onBlur={() => formik.setFieldTouched("fairValue")}
                                                value={formik.values.fairValue}
                                                />
                                            {formik.touched.fairValue && formik.errors.fairValue && (
                                                <span className='text-danger'>{formik.touched.fairValue && formik.errors.fairValue}</span>
                                            )}
                                        </div>
                                    </Col>
                                    <Col md="6">
                                        <div className="mt-4">
                                            <Label for="Amount">
                                                Total Amount under RVG
                                            </Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="probableOwedUnderRVGTotalAmount"
                                                onChange={(e) =>{
                                                    formik.setFieldValue(`probableOwedUnderRVGTotalAmount`, (e.target.value))
                                                }}
                                                value={formik.values.probableOwedUnderRVGTotalAmount}
                                            />
                                            {/* <InputMask
                                                mask="$ 99,999,999"
                                                id="probableOwedUnderRVGTotalAmount"
                                                className="form-control input-color"
                                                // beforeMaskedValueChange={beforeMaskedValueChange}
                                                onChange={(e) =>{
                                                    formik.setFieldValue(`probableOwedUnderRVGTotalAmount`, (e.target.value))
                                                }}
                                                value={formik.values.probableOwedUnderRVGTotalAmount}
                                                >
                                            </InputMask> */}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12">
                                    <div className="d-flex mt-4">
                                        <h6>
                                            Lease term for a major part of remaining economic life of underlying asset?
                                        </h6>
                                        <div className="form-check form-switch ms-3">
                                            <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="economicLifeOfUnderlyingAsset"
                                            checked={formik.values.economicLifeOfUnderlyingAsset}
                                            disabled={true}
                                            // onChange={formik.handleChange}
                                            onBlur={() => formik.setFieldTouched("economicLifeOfUnderlyingAsset")}
                                            //value={formik.values.economicLifeOfUnderlyingAsset}
                                            /> 
                                            {formik.touched.economicLifeOfUnderlyingAsset && formik.errors.economicLifeOfUnderlyingAsset && (
                                                <span className='text-danger'>{formik.touched.economicLifeOfUnderlyingAsset && formik.errors.economicLifeOfUnderlyingAsset}</span>
                                            )}
                                        </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="10">
                                    <div className="d-flex mt-4">
                                        <h6>
                                            <Link to="#">PV of lease payments + RV guaranteed </Link> is at least substantially all of the fair value of the underlying asset?
                                        </h6>
                                        <div className="form-check form-switch ms-3">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="substantiallyAllOfTheFairValue"
                                                onChange={formik.handleChange}
                                                disabled={true}
                                                onBlur={() => formik.setFieldTouched("substantiallyAllOfTheFairValue")}
                                                defaultChecked={formik.values.substantiallyAllOfTheFairValue}
                                                />
                                            {formik.touched.substantiallyAllOfTheFairValue && formik.errors.substantiallyAllOfTheFairValue && (
                                                <span className='text-danger'>{formik.touched.substantiallyAllOfTheFairValue && formik.errors.substantiallyAllOfTheFairValue}</span>
                                            )}
                                        </div>
                                        </div>
                                    </Col>
                                </Row>
                            </React.Fragment>
                            :
                            <React.Fragment>

                            </React.Fragment>
                }
            </Form>
        </React.Fragment>
    )
}

export default AddLeaseStepFive